// import { v4 }                 from "uuid";
import { filterEmptyObjects } from "_common/utils";
import { getUserData }        from "_common/utils/user";
import XHR                    from "_common/xhr";
import { configs }            from "_config/index";

import { getAllBroadcastsByType, getBroadcastSessionByIds } from "../broadcast";

import {
	AddSportsFanCommentOnFeed,
	AddSportsFanShareFeed,
	AddSportsFanViewsFeed,
	addSportsFanReactOnFeed,
	deletePost
} from "./mutations";
import {
	GetCommentsOnFeed,
	GetFeedById_Q1,
	GetFeedById_Q2,
	GetFeedTypes,
	GetFeed_Q2,
	GetReactionsOnFeed,
	gameWisePostQuery,
	generalHomePageFeed,
	generalHomePageFeedV2,
	getShortVideosGraphQl,
	getUserReels,
	getUserUploads,
	sessionRecommendation
} from "./queries";

export const getFeedFilters = async function () {
	const result = await XHR.post.bind ( this ) ( {
		url             : configs.FEED_API,
		data            : { query: GetFeedTypes () },
		backupApiConfig : { fallbackBaseURL: configs.FALLBACK_FEED_API }
	} );

	return result.data.feed_types;
};

export const getPersonalizedFeedData = async function ( {
	pageNo = 1,
	pageSize = 10,
	feedTypeId = null
} ) {
	const result = await XHR.post.bind ( this ) ( {
		url     : configs.FEED_API,
		timeout : 0,
		data    : {
			query     : generalHomePageFeed ( { pageNo, pageSize, feedTypeId } ),
			variables : { pageNo, pageSize, feedTypeId: parseInt ( feedTypeId ) }
		},
		backupApiConfig: { fallbackBaseURL: configs.FALLBACK_FEED_API }
	} );

	return result?.data?.generalHomePageFeed?.mixedFeedAndBroadcast || [];

};
export const getPersonalizedFeedDataV2 = async function ( {
	pageNo = 1,
	pageSize = 10,
	feedTypeId = null
} ) {
	const result = await XHR.post.bind ( this ) ( {
		url     : configs.FEED_API,
		timeout : 0,
		data    : {
			query     : generalHomePageFeedV2 ( { pageNo, pageSize, feedTypeId } ),
			variables : { pageNo, pageSize, tabId: feedTypeId ? parseInt ( feedTypeId ) : null }
		},
		/* headers: {
      "client-request-id": v4 ()
    }, */
		backupApiConfig: { fallbackBaseURL: configs.FALLBACK_FEED_API }
	} );

	if ( feedTypeId ) {
		return result?.data?.generalHomePageFeedV2[0]?.mixedFeedAndBroadcast || [];
	}

	return result?.data?.generalHomePageFeedV2 || [];

};

export const getUserUploadedFeed = async function ( params ) {
	const result = await XHR.post.bind ( this ) ( {
		url  : configs.FEED_API,
		data : { query: getUserUploads ( params ) }
	} );

	if ( result?.data?.get_user_uploads ) {
		const feedData = result.data.get_user_uploads.filter ( item => {
			if ( item.parent_post_type !== "poll" || !item.parent_post_type ) {
				return item;
			}
		} );

		return feedData;
	}
};

export const getFeedById = async function ( params ) {
	const result = await XHR.post.bind ( this ) ( {
		url  : configs.FEED_API,
		data : {
			query     : GetFeedById_Q1 (),
			variables : {
				feed_id: params.feedId
			}
		},
		backupApiConfig: { fallbackBaseURL: configs.FALLBACK_FEED_API }
	} );

	return result.data.feed_by_feed_id;
};

export const getReels = async function ( params ) { // here
	const result = await XHR.post.bind ( this ) ( {
		url  : configs.FEED_API,
		data : { query: getUserReels ( params ) }
	} );

	return result?.data?.userReels;
};

export const postLikeOnFeed = async ( { feedId } ) => {
	const userData = getUserData ();
	const result   = await XHR.post ( {
		url               : configs.FEED_API,
		timeout           : 5000,
		data              : { query: addSportsFanReactOnFeed ( { feedId, userId: userData.userId } ) },
		transformResponse : [
			data => {
				return { ...JSON.parse ( data ), isAuthenticated: true };
			}
		]
	} );

	return result;
};

export const getCommentsOnFeed = async params => {
	const result = await XHR.post ( {
		url  : configs.FEED_API,
		data : { query: GetCommentsOnFeed ( params ) }
	} );

	return result.data.sports_fan_comment_on_feed_by_feed_id;
};

export const getReactionsOnFeed = async params => {
	const result = await XHR.post ( {
		url  : configs.FEED_API,
		data : { query: GetReactionsOnFeed ( params ) }
	} );

	return result.data.sports_fan_react_on_feed_by_feed_id;
};

export const postCommentOnFeed = async ( { feedId, commentMessage } ) => {
	const userData = getUserData ();
	const res      = await XHR.post ( {
		url               : configs.FEED_API,
		timeout           : 5000,
		transformResponse : [
			data => {
				return { ...JSON.parse ( data ), isAuthenticated: true };
			}
		],
		data: { query: AddSportsFanCommentOnFeed ( { userId: userData.userId, feedId, commentMessage } ) }
	} );

	return res?.data?.add_sports_fan_comment_on_feed;
};

export const getFeedsByIds = async function ( params ) {
	if ( !params?.feedIds && !params?.feedIds?.length ) return;

	try {
		const result = await XHR.post.bind ( this ) ( {
			url  : configs.FEED_API,
			data : {
				query     : GetFeedById_Q2 (),
				variables : {
					feed_ids: params.feedIds
				}
			}
		} );

		return result.data.feeds_by_feed_ids;
	} catch {
		return [];
	}
};

export const getSearchResults = async params => {
	const { searchTerm, pageNo, pageSize } = params;

	try {
		const queryParams = pageNo && pageSize ? {
			searchTerm,
			pageNo,
			pageSize
		} : {
			searchTerm
		};

		const result = await XHR.get ( {
			url    : `${ configs.CONSUMER_FEED_URL }search/v2/all`,
			params : queryParams
		} );

		const filteredResult = {
			users : filterEmptyObjects ( "id", result.creatorsInfo ),
			feed  : []
		};

		result.feed         = [];
		const postMinLength = Math.min ( result.staticPostIds.length, result.livePostIds.length );

		for ( let i = 0; i < postMinLength; i ++ ) {
			result.feed.push ( result.livePostIds[i], result.staticPostIds[i] );
		}
		result.feed.push ( ...result.livePostIds.slice ( postMinLength ), ...result.staticPostIds.slice ( postMinLength ) );

		if ( result?.feed?.length > 0 ) {
			const params = {
				feedIds: result.feed
			};
			let feedData = await getFeedsByIds ( params );

			feedData = feedData.filter ( item => {
				if ( item.parent_post_type !== "poll" || !item.parent_post_type ) {
					return item;
				}
			} );

			const updatedResultObj = {
				...filteredResult,
				feed: filterEmptyObjects ( "id", feedData )
			};

			return updatedResultObj;
		}

		return filteredResult;
	} catch ( e ) {
		const emptyObj = {
			feed  : [],
			users : []
		};

		return emptyObj;
	}
};

export const getPaginatedSearchResults = async params => {
	const { type, searchTerm, pageNo, pageSize } = params;
	let copyType                                 = type;

	if ( type === "people" ) {
		copyType = "creatorsInfo";
	}

	try {
		const result = await XHR.get ( {
			url    : `${ configs.CONSUMER_FEED_URL }search/v2/${ type }`,
			params : {
				searchTerm,
				pageNo,
				pageSize
			}
		} );

		if ( result?.staticPostIds.length > 0 ) {
			let feedArray = {
				[type]: []
			};

			if ( result.staticPostIds.length > 0 ) {
				const params = {
					feedIds: result.staticPostIds
				};
				let feedData = await getFeedsByIds ( params );

				feedData  = feedData.filter ( item => {
					if ( item.parent_post_type !== "poll" || !item.parent_post_type ) {
						return item;
					}
				} );
				feedArray = {
					[type]: filterEmptyObjects ( "id", feedData )
				};
			}

			return feedArray;
		}

		return {
			[type]: filterEmptyObjects ( type === "players" ? "playerId" : "id", result[copyType] )
		};

	} catch ( e ) {
		return {
			[type]: []
		};
	}
};

export const getPopularTopics = async () => {
	const result = await XHR.get ( {
		url: `${ configs.FEED_POPULATOR_URL }trendingFeedTopics`
	} );

	return result;
};

export const getVideosList = async ( {
	pageNo,
	pageSize = 10
} ) => {
	const result = await XHR.post ( {
		url     : configs.FEED_API,
		timeout : 15000,
		data    : {
			query     : GetFeed_Q2 (),
			variables : {
				pageNo,
				pageSize,
				feedTypeIds: [ 19 ]
			}
		}
	} );

	return result.data.personalized_feed_V2.feed;
};

export const uploadVideo = async ( { formData, config } ) => {

	const response = await XHR.post ( {
		url     : `${ configs.FEED_POPULATOR_URL }userVideoUploadV2`,
		headers : {
			"Content-Type": "multipart/form-data"
		},
		data    : formData,
		timeout : 24 * 60 * 60 * 1000,
		config
	} );

	return response;
};

export const uploadReels = async ( { formData, config } ) => {

	const response = await XHR.post ( {
		url     : `${ configs.FEED_POPULATOR_URL }reels`,
		headers : {
			"Content-Type": "multipart/form-data"
		},
		data    : formData,
		timeout : 24 * 60 * 60 * 1000,
		config
	} );

	return response;
};

export const getLocaleMasters = async function () {
	const response = await XHR.get.bind ( this ) ( {
		url: `${ configs.FEED_POPULATOR_URL }localeMasters`
	} );

	return response.filter ( item => item.localeKey !== "de" );
};

export const trendingFeedTopicsByGenre = async () => {
	const result = await XHR.get ( {
		url: `${ configs.FEED_POPULATOR_URL }trendingFeedTopicsByGenre`
	} );

	return result;
};

export const getSearchTabs = async function () {
	const result = await XHR.get.bind ( this ) ( {
		url: `${ configs.CONSUMER_FEED_URL }search/v2/tabs`
	} );

	return result;
};

function splicer ( array, element, index ) {
	array.splice ( index * 2, 0, element );

	return array;
}

function weave ( array1, array2 ) {
	return array1.reduce ( splicer, array2.slice () );
}

export const getSearchTabResults = async function ( params ) {
	const { key, pageNo, searchQuery } = params;

	if ( !key || !searchQuery )
		return [];

	switch ( key ) {
		case "all": {
			const pageSize = 20;
			let results    = {};

			try {
				results = await getSearchAllResults.bind ( this ) ( pageNo, pageSize, searchQuery );
			} catch ( err ) {
				return {
					mergedVideos : [],
					topCreator   : {},
					reels        : []
				};

			}

			let broadcastSessions = [];
			let videos            = [];
			let topCreator        = {};
			let reels             = [];

			if ( results?.topCreator ) {
				topCreator = results?.topCreator;
			}

			const params = {
				feedIds: results.staticPostIds
			};

			const reelParam = {
				feedIds: results.reelIds
			};

			const response = await Promise.allSettled (
				[
					getBroadcastSessionByIds.bind ( this ) ( results?.livePostIds ),
					getFeedsByIds.bind ( this ) ( params ),
					getFeedsByIds.bind ( this ) ( reelParam )
				]
			);

			response.forEach ( ( result, index ) => {
				if ( result.status === "fulfilled" ) {
					switch ( index ) {
						case 0:
							broadcastSessions = result.value;
							break;

						case 1:
							videos = result.value;
							break;

						case 2:
							reels = result.value;
					}
				}

				if ( index === 0 && result.status === "rejected" && result.reason?.message !== "TOKEN_EXPIRED" )
					broadcastSessions = [];

				if ( index === 1 && result.status === "rejected" && result.reason?.message !== "TOKEN_EXPIRED" )
					videos = [];
				if ( index === 2 && result.status === "rejected" && result.reason?.message !== "TOKEN_EXPIRED" )
					reels = [];
			} );

			// function for alternating broadcast and videos
			let mergedVideos = weave ( broadcastSessions, videos );

			return {
				mergedVideos,
				topCreator,
				reels
			};
		}

		case "people": {
			const pageSize = 10;

			try {
				const results = await getSearchStreamers.bind ( this ) ( pageNo, pageSize, searchQuery );

				return results?.creatorsInfo;
			} catch ( err ) {
				return {};
			}

		}

		case "liveStreams": {
			const pageSize = 10;

			try {
				const results         = await getSearchLiveStreams.bind ( this ) ( pageNo, pageSize, searchQuery );
				let broadcastSessions = [];

				if ( results?.livePostIds && results?.livePostIds.length > 0 ) {
					broadcastSessions = await getBroadcastSessionByIds.bind ( this ) ( results?.livePostIds );
				}

				return broadcastSessions;
			} catch ( err ) {
				return [];
			}

		}

		case "videos": {
			const pageSize = 10;

			try {
				const results = await getSearchVideos.bind ( this ) ( pageNo, pageSize, searchQuery );
				let videos    = [];

				if ( results?.staticPostIds && results?.staticPostIds.length > 0 ) {
					const params = {
						feedIds: results.staticPostIds
					};

					videos = await getFeedsByIds.bind ( this ) ( params );
				}

				return videos;
			} catch ( err ) {
				return [];
			}
		}

		case "images": {
			const pageSize = 10;

			try {
				const results = await getSearchImages.bind ( this ) ( pageNo, pageSize, searchQuery );
				let images    = [];

				if ( results?.staticPostIds && results?.staticPostIds?.length > 0 ) {
					const params = {
						feedIds: results.staticPostIds
					};

					images = await getFeedsByIds.bind ( this ) ( params );
				}

				return images;
			} catch ( err ) {
				return [];
			}

		}

		case "reels": {
			const pageSize = 10;

			try {
				const results = await getSearchReels.bind ( this ) ( pageNo, pageSize, searchQuery );
				let reels     = [];

				if ( results?.reelIds && results?.reelIds.length > 0 ) {
					const params = {
						feedIds: results.reelIds
					};

					reels = await getFeedsByIds.bind ( this ) ( params );
				}

				return reels;
			} catch ( err ) {
				return [];
			}
		}

		default:
			return null;
	}
};

export const getSearchAllResults = async function ( pageNo = 1, pageSize = 20, searchQuery ) {
	const result = await XHR.get.bind ( this ) ( {
		url: `${ configs.CONSUMER_FEED_URL }search/v2/all?searchTerm=${ encodeURIComponent ( searchQuery ) }&pageNo=${ pageNo }&pageSize=${ pageSize }`
	} );

	return result;
};

export const getSearchStreamers = async function ( pageNo = 1, pageSize = 10, searchQuery, gameSearch ) {
	const result = await XHR.get.bind ( this ) ( {
		url: `${ configs.CONSUMER_FEED_URL }search/v2/people?searchTerm=${ encodeURIComponent ( searchQuery ) }&pageNo=${ pageNo }&pageSize=${ pageSize }&gameSearch=${ gameSearch }`
	} );

	return result;
};

export const getSearchLiveStreams = async function ( pageNo = 1, pageSize = 10, searchQuery ) {
	const result = await XHR.get.bind ( this ) ( {
		url: `${ configs.CONSUMER_FEED_URL }search/v2/liveStreams?searchTerm=${ encodeURIComponent ( searchQuery ) }&pageNo=${ pageNo }&pageSize=${ pageSize }`
	} );

	return result;
};

export const getGameWiseFeed = async function ( { pageNo = 1, pageSize = 10, gameId, typeId } ) {

	const res = await XHR.post.bind ( this ) ( {
		url  : configs.FEED_API,
		data : { query: gameWisePostQuery ( { pageNo, pageSize, gameId, typeId } ) }
	} );

	return res?.data?.getGameWisePost?.mixedFeedAndBroadcast || [];
};

export const getSearchVideos = async function ( pageNo = 1, pageSize = 10, searchQuery ) {
	const result = await XHR.get.bind ( this ) ( {
		url: `${ configs.CONSUMER_FEED_URL }search/v2/videos?searchTerm=${ encodeURIComponent ( searchQuery ) }&pageNo=${ pageNo }&pageSize=${ pageSize }`
	} );

	return result;
};

export const getSearchReels = async function ( pageNo = 1, pageSize = 10, searchQuery ) {
	const result = await XHR.get.bind ( this ) ( {
		url: `${ configs.CONSUMER_FEED_URL }search/v2/reels?searchTerm=${ encodeURIComponent ( searchQuery ) }&pageNo=${ pageNo }&pageSize=${ pageSize }`
	} );

	return result;
};

export const checkVideoUploadStatus = async ( { feedId } ) => {
	const result = await XHR.get ( {
		url: `${ configs.FEED_POPULATOR_URL }checkVideoUploadStatus?feedId=${ feedId }`
	} );

	return result;
};

export const getSearchImages = async function ( pageNo = 1, pageSize = 10, searchQuery ) {
	const result = await XHR.get.bind ( this ) ( {
		url: `${ configs.CONSUMER_FEED_URL }search/v2/images?searchTerm=${ encodeURIComponent ( searchQuery ) }&pageNo=${ pageNo }&pageSize=${ pageSize }`
	} );

	return result;
};

export const updateShareCount = async ( { feedId, userId } ) => {

	const res = await XHR.post ( {
		url  : configs.FEED_API,
		data : { query: AddSportsFanShareFeed ( { userId, feedId } ) }
	} );

	return res;
};

export const updateViewCount = async feedId => {
	const userData = getUserData ();

	await XHR.post ( {
		url  : configs.FEED_API,
		data : { query: AddSportsFanViewsFeed ( { userId: userData.userId, feedId } ) }
	} );

	return;
};
export const deleteStaticPost = async feedId => {

	const res = await XHR.post ( {
		url               : configs.FEED_API,
		timeout           : 5000,
		transformResponse : [
			data => {
				return { ...JSON.parse ( data ), isAuthenticated: true };
			}
		],
		data: { query: deletePost ( feedId ) }
	} );

	return res?.data;
};

export const getReportTags = async () => {
	const response = await XHR.get ( {
		url: `${ configs.FEED_POPULATOR_URL }reportingReasonTypes`
	} );

	return response;
};

export const reportPost = async data => {
	const result = await XHR.post ( {
		url     : `${ configs.FEED_POPULATOR_URL }reportPost`,
		headers : {
			"Content-Type": "application/json"
		},
		data
	} );

	if ( !result )
		return false;

	return result;
};

export const getGameTabResults = async function ( params ) {
	const { key, pageNo, searchQuery, gameId, androidPackageName, gameSearch = false } = params;

	if ( !key || !searchQuery ) return [];

	switch ( key ) {

		case "people": {
			const pageSize = 10;
			const results  = await getSearchStreamers.bind ( this ) ( pageNo, pageSize, searchQuery, gameSearch );

			return results?.creatorsInfo;
		}

		case "videos": {
			// In typeId you have to send the ID of the type of content you want:
			// VIDEOS: 2
			// REELS: 5
			const result = await getGameWiseFeed.bind ( this ) ( { pageNo, pageSize: 10, gameId, typeId: 2 } );

			return result;
		}

		case "reels": {
			const result = await getGameWiseFeed.bind ( this ) ( { pageNo, pageSize: 10, gameId, typeId: 5 } );

			return result;

		}

		case "live": {
			const result = await getAllBroadcastsByType.bind ( this ) ( { pageNo, pageSize: 10, androidPackageName } );

			return result;

		}

		default:
			return null;
	}
};

export const getSEOTags = async function ( { pageName } ) {
	const response = await XHR.get.bind ( this ) ( {
		url: `${ configs.USER_API_URL }management/seoTags?seoTitle=${ pageName }`
	} );

	return response;
};

export async function getShortVideos ( { pageNo, pageSize } ) {
	const response = await XHR.post.bind ( this ) ( {
		url  : configs.FEED_API,
		data : { query: getShortVideosGraphQl ( { pageNo, pageSize } ) }
	} );

	return response?.data?.user_short_videos;
}

export const getRecommendedSessions = async function ( {
	pageNo = 1,
	pageSize = 10,
	broadcastId = null
} ) {
	const result = await XHR.post.bind ( this ) ( {
		url     : configs.FEED_API,
		timeout : 0,
		data    : {
			query     : sessionRecommendation (),
			variables : { pageNo, pageSize, sessionId: parseInt ( broadcastId ) }
		},
		backupApiConfig: { fallbackBaseURL: configs.FALLBACK_FEED_API }
	} );

	return result?.data?.sessionRecommendation?.mixedFeedAndBroadcast || [];
};

export const getDonationLeaderboard = async function () {
	const response = await XHR.get.bind ( this ) ( {
		url: `${ configs.USER_API_URL }leaderboard/stream-donation`
	} );

	return response;
};
