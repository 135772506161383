export const addSportsFanReactOnFeed = ( { feedId, userId } ) => {
	return `mutation addSportsFanReactOnFeed { 
              add_sports_fan_react_on_feed(feed_id: ${ feedId }, sports_fan_id: ${ userId }, reaction: "AGREE") {
                __typename
                reaction_count
              }
            }`;
};

export const AddSportsFanCommentOnFeed = ( { feedId, userId, commentMessage } ) => {
	return `
          mutation AddSportsFanCommentOnFeed { 
          add_sports_fan_comment_on_feed(feed_id: ${ feedId }, sports_fan_id: ${ userId },  comment_message:${ JSON.stringify ( commentMessage ) }) {
            __typename
            reaction_count
            comment_id  
          }
        }`;
};

export const AddSportsFanShareFeed = ( { userId, feedId } ) => {
	return `mutation UpdateShareCount {
          add_sports_fan_share_feed(sports_fan_id: ${ userId }, feed_id: ${ feedId }, medium: "Whatsapp", count: 1){
            __typename
            id
            share_count
          }
        }`;
};

export const AddSportsFanViewsFeed = ( { userId, feedId } ) => {
	return `mutation AddSportsFanViewsFeed {
                add_sports_fan_views_feed(feed_id: [${ feedId }], sports_fan_id: ${ userId }) {
                __typename
                  insertId
                  changedRows
                }
        }`;
};
export const deletePost = feedId => {
	return `
          mutation deletePost { 
            remove_upload(feed_id: ${ feedId }) 
        }`;
};
