const FeedMedia = `
  fragment FeedMedia on feed_media {
    __typename
    id
    feedId: feed_id
    href
    mediaType: media_type
    mediaText: media_text
    mediaSize: media_size
    mediaSequence: media_sequence
  }
`;

export const SportsFanDetails = `
  fragment SportsFanDetails on sports_fan_details {
    __typename
    id
    name
    photo
    isCeleb
    isFollowing
    profileLink
    followerCount
  }
`;

export const SportsFan = `
fragment SportsFan on sportsFan {
    __typename
    id
    isCeleb
    name
    photo
    isFollowing
  }    
`;

export const Broadcaster = `
fragment Broadcaster on broadcaster {
    __typename
    id
    sportsFan {
      __typename
      ...SportsFan
    }
  }
  ${ SportsFan }
`;

const SportsFanReaction = `
  fragment SportsFanReactOnFeed on sports_fan_react_on_feed {
    __typename
    id
    feedId: feed_id
    sportsFanId: sports_fan_id
    reactionId: reaction_id
    count: reaction_count
    relatedReactionMaster: related_reaction_master {
      __typename
      reaction
      id
    }
    sportsFanDetails: sports_fan_details {
      __typename
      ...SportsFanDetails
    }
  }
  ${ SportsFanDetails }
`;

export const HorizontalFeed = `
  fragment Feed on feed {
      __typename
      id
      locale
      title
      summary
      createdAt: created_at
      permalink
      feedType: feed_type
      media {
        __typename
        ...FeedMedia
      }
      actorDetails: actor_details {
        __typename
        ...SportsFanDetails
      }
      views: views_count
      tags {
        __typename
        id
        feedTag: feed_tag
      }
    }
    ${ FeedMedia }
    ${ SportsFanDetails }
`;

export const VerticalFeed = `
  fragment Feed on feed {
    __typename
    id
    locale
    title
    summary
    createdAt: created_at
    parent_post_type
    permalink
    feedType: feed_type
    isReactedOnFeed
    media {
      __typename
      ...FeedMedia
    }
    actorDetails: actor_details {
      __typename
      ...SportsFanDetails
    }
    views: views_count
    tags {
      __typename
      id
      feedTag: feed_tag
    }
    read_time
    commentCount: comment_count
    sharesCount: shares_count
    reactions {
      __typename
      reactionId: reaction_id
      count: reaction_count
      reaction
    }
    sportsFanReaction: sports_fan_reaction {
      __typename
      ...SportsFanReactOnFeed
    }
    game{
      id
      gameName
    }
  }
  ${ FeedMedia }
  ${ SportsFanReaction }
`;

export const combinedFeedAndBroadcast = `
  fragment combinedFeedAndBroadcast on combinedFeedAndBroadcast {

    feed {
      ...Feed
    }

    broadcast {
      ...broadcast
    }
  }

  fragment broadcast on broadcastSession {
    __typename
    id
    broadcaster {
      __typename
      ...Broadcaster
    }
    startTimeUTC
    endTimeUTC
    isLive
    cdnUrl
    seekFeatureEnabled
    streamingURL
    mediaType
    sessionInfo
    sessionType
    description
    resolution
    thumbnail
    isLeaderBoardLive
    isReacted
    androidPackageName
    game {
      name
      banner
    }
    totalViews
    views
    liveViews
    totalComments
    sortingScore
    totalReactions
  }

  ${ Broadcaster }
  ${ VerticalFeed }

`;

export const Comments = `
	fragment Comments on sports_fan_comment_on_feed {
          __typename
          id
          sportsFanId     : sports_fan_id
          commentMessage  : comment_message
          sportsFanDetails: sports_fan_details {
            __typename
            ...SportsFanDetails
          }
        }
`;

export const UserReaction = `
	fragment SportsFanReactOnFeed on sports_fan_react_on_feed {
            __typename
            id
            sportsFanId     : sports_fan_id
            sportsFanDetails: sports_fan_details {
              __typename
              ...SportsFanDetails
            }
          }
`;
