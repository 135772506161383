import {
	Comments,
	SportsFanDetails,
	UserReaction,
	VerticalFeed,
	combinedFeedAndBroadcast
} from "./fragments";

export const GetFeedTypes = () => {
	return `query GetFeedTypes {
                feed_types {
                    __typename
                    id
                    display_name
                }
            }`;
};

export const GetFeed_Q1 = () => {
	return `query GetFeed ($pageNo: Int!, $pageSize: Int!, $feedTypeIds: [Int]) { 
		  personalized_feed_V2(
					pageNo      : $pageNo
					pageSize    : $pageSize
					feedTypeIds : $feedTypeIds
		  ){    __typename
			  ...combinedFeedAndBroadcast
			}
		  }
		   ${ combinedFeedAndBroadcast }
		  `;
};

export const generalHomePageFeed = () => {
	return `query homepage($pageNo: Int!, $pageSize: Int!, $feedTypeId: Int) {
		generalHomePageFeed(pageNo: $pageNo, pageSize: $pageSize, feedTypeId: $feedTypeId){
		  mixedFeedAndBroadcast{
			id
			read_time
			typeName
			media {
				feed_id
				href
				media_type
				media_size
				media_sequence
			  }
			views_count
			broadcaster {
				sportsFan {
				  id
				  name
				  isCeleb
				  photo
				  isFollowing
				}
			  }
			sessionInfo
			actor_details_v2 {
			  id
			  name
			  photo
			  isFollowing
			  isCeleb
			}
			thumbnail
			startTimeUTC
			endTimeUTC
			streamingURL
			cdnUrl
			title
			views
			liveViews
			totalViews
			created_at
		  }
		}
	  }`;
};
export const generalHomePageFeedV2 = () => {
	return `query homepageV2($pageNo: Int!, $pageSize: Int!, $tabId: Int) {
		generalHomePageFeedV2(pageNo: $pageNo, pageSize: $pageSize, tabId: $tabId){
			tabId
			tabName
		  mixedFeedAndBroadcast{
			id
			read_time
			typeName
			streamTier
			media {
				feed_id
				href
				media_type
				media_size
				media_sequence
			  }
			views_count
			broadcaster {
				sportsFan {
				  id
				  name
				  isCeleb
				  photo
				  isFollowing
				}
			  }
			sessionInfo
			actor_details_v2 {
			  id
			  name
			  photo
			  isFollowing
			  isCeleb
			}
			thumbnail
			startTimeUTC
			endTimeUTC
			streamingURL
			cdnUrl
			title
			midTierRemainingAdWatchCount
			views
			liveViews
			totalViews
			created_at
			totalReactions
			totalComments
			totalShares
			reactions {
				reaction
				reaction_id
				reaction_count
			}
			isReacted
			shares_count
			comment_count
		  }
		}
	  }`;
};

export const gameWisePostQuery = ( { pageNo, pageSize, gameId, typeId } ) => {
	return `query gameWisePostQuery{
		getGameWisePost(typeId:${ typeId },pageNo:${ pageNo },pageSize:${ pageSize },gameId:${ gameId }){
			mixedFeedAndBroadcast{
				id
				title
				read_time
				views_count
				created_at
				description
				actor_details_v2 {
					id
					name
					photo
					isFollowing
					isCeleb
				}
				locale
				feed_type
				summary
				media {
					media_sequence
					feed_id
					id
					href
					media_type
					media_size
				}
				created_at
			}
		}
	  }`;
};

export const GetFeed_Q2 = () => {
	return `query GetFeed ($pageNo: Int!, $pageSize: Int!, $feedTypeIds: [Int]) {
				personalized_feed_V2(
				    pageNo      : $pageNo
				    pageSize    : $pageSize
					feedTypeIds : $feedTypeIds
				 ) {
			    __typename
				    feed {
				      ...Feed
		            }
				  }
		    }
      ${ VerticalFeed }
          `;
};

export const getUserReels = params => {
	const { pageNo, pageSize, sportsFanId } = params;

	const id = parseInt ( sportsFanId );

	let queryArguments = `
	pageNo       : ${ pageNo }
	pageSize     : ${ pageSize }
	sportsFanId: ${ id }
	`;

	return `query getUserReels {
				userReels( ${ queryArguments }) {
					__typename
					...Feed
				  }
				}
			  ${ VerticalFeed }
			  `;
};

export const getShortVideosGraphQl = params => {
	const { pageNo, pageSize } = params;

	// doesnot have pagination in this api
	let queryArguments = `
	pageNo       : ${ pageNo }
	pageSize     : ${ pageSize }
	`;

	return `query reels{
		user_short_videos( ${ queryArguments }) {
			__typename
			...Feed
		  }
		}
	  ${ VerticalFeed }
	  `;
};

export const GetFeedById_Q1 = () => {
	return `query GetFeedById ($feed_id: Int!) {
			  feed_by_feed_id(feed_id: $feed_id) {
				__typename
				...Feed
			  }
			}
		  ${ VerticalFeed }
			`;
};

export const GetFeedById_Q2 = () => {
	return `query GetFeedById ($feed_ids: [Int]){
			  feeds_by_feed_ids(feed_ids: $feed_ids) {
				__typename
				...Feed
			  }
			}
			${ VerticalFeed }
			`;
};

export const getUserUploads = ( { pageNo, pageSize, sportsFanId, type } ) => {
	let queryArguments = `
		pageNo       : ${ pageNo }
		pageSize     : ${ pageSize }
		sports_fan_id: ${ sportsFanId },
		mediaType    : "IMAGE"
	`;

	if ( type === "video" ) {
		queryArguments = `
			pageNo       : ${ pageNo }
			pageSize     : ${ pageSize }
			sports_fan_id: ${ sportsFanId },
			feedTypeIds  : [19]
		`;
	}

	return `query getUserUploads {
			  get_user_uploads(
					 ${ queryArguments }
					) {
							  __typename
							  ...Feed  
					  }
					  }
					${ VerticalFeed }
				`;
};

export const GetCommentsOnFeed = ( { feedId, pageSize, pageNo } ) => {
	return `
        query GetCommentsOnFeed{
          sports_fan_comment_on_feed_by_feed_id(feed_id: ${ feedId }, pageNo: ${ pageNo }, pageSize: ${ pageSize }) {
            __typename
            ...Comments
          }
        }
		${ Comments }
		${ SportsFanDetails }
          `;
};

export const GetReactionsOnFeed = ( { feedId, pageNo, pageSize, reaction } ) => {
	return `
        query GetReactionsOnFeed{
          sports_fan_react_on_feed_by_feed_id(feed_id: ${ feedId }, pageNo: ${ pageNo }, pageSize: ${ pageSize }, reaction:${ JSON.stringify ( reaction ) }) {
            __typename
            ...SportsFanReactOnFeed
          }
        }
		${ UserReaction }
		${ SportsFanDetails }
          `;
};

export const sessionRecommendation = () => {
	return `query sessionRecommendation($pageNo: Int!, $pageSize: Int!, $sessionId: Int!) {
        sessionRecommendation(pageNo: $pageNo, pageSize: $pageSize, sessionId: $sessionId) {
          mixedFeedAndBroadcast {
            id
            typeId
            typeName
            liveSeek
            categoryId
            midTierRemainingAdWatchCount
            streamTier
            deviceType
            agoraSessionId
            sessionInfo
            description
            backgroundImage
            thumbnail
            localeKey
            mediaType
            startTimeUTC
            endTimeUTC
            streamingURL
            seekFeatureEnabled
            sportId
            tournamentId
            matchId
            androidPackageName
            topicId
            sessionType
            resolution
            matchRank
            tournamentRank
            isLive
            pinnedCommentId
            totalViews
            ivsChatEnabled
            pollsEnabled
            views
            liveViews
            totalReactions
            totalComments
            totalShares
            totalViewedMinutes
            uniqueAudienceTillNow
            uniqueSportsFanAudienceTillNow
            coHostCount
            coHostSportsFanIds
            coHostUserSportsFanIds
            realLiveViews
            sortingScore
            cdnUrl
            isLeaderBoardLive
            giveAwayCoins
            playWithFriends
            donationGoal
            isReacted
            locale
            title
            sub_title
            summary
            permalink
            author_name
            slug
            actor_type_id
            actor_type
            actor_id
            userSportsFanId
            parent_post_type
            parent_post_id
            feed_type
            downloadUrl
            shareUrl
            pqId
            read_time
            comment_count
            shares_count
            parentFeedId
            views_count
            downloads_count
            created_at
            category {
                id
                category
                onlyLive
            }
            broadcaster {
                id
                shortBio
                agoraChannel
                avgListenerCountPerSession
                totalListeningMinutes
                latestDayStreak
                streakStartDate
                streakEndDate
                totalSessionCount
                totalBroadcastingMinutes
                last30daySessionCount
                followerCount
                sportsFan {
                    id
                    userSportsFanId
                    name
                    isCeleb
                    photo
                    city
                    locale
                    userRole
                    isFollowing
                }
            }
            game {
                id
                name
                androidPackageName
                image
                totalStreams
                appUrl
                banner
                isDesktopGame
                desktopImage
            }
            sports_fan_reaction {
                id
                feed_id
                sports_fan_id
                userSportsFanId
                reaction_id
                reaction_count
                userRole
            }
            reactions {
                reaction_count
                reaction_id
                reaction
            }
            parentFeed {
                id
                locale
                title
                sub_title
                description
                summary
                permalink
                author_name
                slug
                actor_type_id
                actor_type
                actor_id
                userSportsFanId
                parent_post_type
                parent_post_id
                feed_type
                pqId
                read_time
                comment_count
                shares_count
                parentFeedId
                views_count
                downloads_count
                created_at
                downloadUrl
                isReactedOnFeed
                shareUrl
            }
            comments {
                id
                feed_id
                sports_fan_id
                userSportsFanId
                parent_comment_id
                comment_message
                deleted_by_user
                edited_by_user
                replies
            }
            options {
                sportsFanOption
                ended
                endTime
                mediaType
                backgroundImage
            }
            tags {
                id
                feed_tag
            }
            media {
                id
                feed_id
                href
                media_type
                media_text
                media_size
                media_sequence
            }
            actor_details_v2 {
                id
                userSportsFanId
                name
                photo
                isFollowing
                mobile
                profileLink
                isCeleb
                isBlocked
                userRole
                followerCount
            }
            videoGame {
                id
                gameName
            }
        }
      }
    }`;
};
